<template>
    <div class="MyTreeSelect">
        <van-field v-model="inputValue" clickable readonly v-bind="$attrs" @click-input="showPopup = true">
            <van-icon v-if="!$attrs.disabled && $attrs.clearable && inputValue"
                      slot="right-icon"
                      name="clear"
                      @click="onClear"
            />
        </van-field>
        <my-drawer v-model="showPopup"
                   :fullscreen="false"
                   :nav-left-arrow="false"
                   position="bottom"
                   round
        >
            <van-nav-bar slot="nav" :border="false">
                <span slot="left" style="color: #323233;">{{ $attrs.placeholder }}</span>
                <van-icon slot="right" color="#323233" name="cross" size="18" @click="showPopup = false;" />
            </van-nav-bar>
            <van-tree-select :active-id.sync="activeId"
                             :items="$attrs.items"
                             :main-active-index.sync="activeIndex"
                             @click-item="onClickItem"
            />
        </my-drawer>
    </div>
</template>

<script>
    import MyDrawer from "../drawer";

    export default {
        name: "MyTreeSelect",
        inheritAttrs: false,
        components: {MyDrawer},
        props: {
            value: Array,
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                showPopup: false,
                inputValue: '',
                activeId: 0,
                activeIndex: 0,
            }
        },
        computed: {
            items() {
                return this.$attrs.items;
            }
        },
        watch: {
            value: {
                handler: 'setDefault'
            }
        },
        mounted() {
            this.setDefault();
        },
        methods: {
            onClickItem(data) {
                let items = this.items;
                let item = items[this.activeIndex];
                // this.activeId = data.value;
                this.inputValue = `${item.text} / ${data.text}`;
                this.$emit('input', [item.id, data.id]);
                this.showPopup = false;
            },
            onClear() {
                this.inputValue = '';
                this.$emit('input', []);
            },
            // 回显默认值
            setDefault() {
                if (this.value && this.value.length > 0) {
                    let [v1, v2] = this.value;
                    this.activeIndex = this.items.findIndex(v => v.id === v1);
                    let item = this.items[this.activeIndex];
                    if (item) {
                        let {id, text} = item.children.find(item => item.id === v2);
                        this.activeId = id;
                        this.inputValue = `${item.text} / ${text}`;
                    }
                } else {
                    this.inputValue = '';
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .van-sidebar-item--select::before {
        background: #1677FF;
    }
</style>
